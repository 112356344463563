import { defineStore } from 'pinia'

import orderClient from '@/js/api/orders.js'
import { useSessionStore } from './session'

const INITIAL_STATE = {
	orderTax: 0,
	orderTaxFormatted: '',
	shippingTotal: 0,
	shippingTotalFormatted: '',
	subtotal: 0,
	subtotalFormatted: '',
	subtotalBeforeOrderDiscounts: 0,
	subtotalBeforeOrderDiscountsFormatted: '',
	taxTotal: 0,
	taxTotalFormatted: '',
	total: 0,
	totalFormatted: '',
	weightTotal: 0,
	hasCalculation: false,
}

export const useOrderStore = defineStore('order', {
	state: () => ({
		...INITIAL_STATE,
	}),

	getters: {},

	actions: {
		async calculate(data) {
			const { discountCode, shipMethodId, shippingAddressId, donationAmount } = data
			const session = useSessionStore()
			const payload = {
				discountCode,
				shipMethodId,
				shippingAddressId,
				donationAmount,
				country: session.country_code,
				warehouse: session.warehouse_id,
				currencyCode: session.currency_code,
				priceType: session.price_type_id,
			}
			try {
				const calculation = await orderClient.calculate(payload)
				const { data } = calculation
				const {
					orderTax,
					shippingTotal,
					subtotal,
					subtotalBeforeOrderDiscounts,
					taxTotal,
					total,
					weightTotal,
					shippingOptions,
					orderTaxFormatted,
					shippingTotalFormatted,
					subtotalFormatted,
					subtotalBeforeOrderDiscountsFormatted,
					taxTotalFormatted,
					totalFormatted,
				} = data
				this.orderTax = orderTax
				this.shippingTotal = shippingTotal
				this.subtotal = subtotal
				this.subtotalBeforeOrderDiscounts = subtotalBeforeOrderDiscounts
				this.taxTotal = taxTotal
				this.total = parseFloat(total.toFixed(2)) // prevent extended decimals
				this.weightTotal = weightTotal
				// formatted versions
				this.orderTaxFormatted = orderTaxFormatted
				this.shippingTotalFormatted = shippingTotalFormatted
				this.subtotalFormatted = subtotalFormatted
				this.subtotalBeforeOrderDiscountsFormatted = subtotalBeforeOrderDiscountsFormatted
				this.taxTotalFormatted = taxTotalFormatted
				this.totalFormatted = totalFormatted
				this.hasCalculation = true
				return { shippingOptions }
			} catch (error) {
				throw error
			}
		},
		clearCalculation() {
			this.calculation = INITIAL_STATE
			this.hasCalculation = false
		},
	},
})
